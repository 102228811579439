import { ReactComponent as DarkLogo } from 'assets/logo/DNV_logo_WHITE.svg';
import { ReactComponent as LightLogo } from 'assets/logo/DNV_logo_RGB.svg';
import useSettings from 'hooks/useSettings';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  basic: {
    fill: theme.palette.type === 'dark' ? theme.palette.logo.main : '#000',
    height: 50,
  },
}));

const useLogo = (props) => {
  const { settings } = useSettings();
  const classes = useStyles();
  const isLightTheme = settings.theme === 'LIGHT';
  const src = isLightTheme ? '/static/logo/Involve_logo_RGB.png' : '/static/logo/Involve_logo_WHITE.png';

  return <img className={classes.basic} src={src} />;
};

export default useLogo;
